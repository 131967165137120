import * as Category from '../../domain/Category';
import * as list from '../categories/list';

/**
 * reducer
 */
export type State = Category.EntityList;

export const defaultState = new Category.EntityList();

const init = (state: object) => Category.EntityList.fromJS(state);
const replace = (state: State, action: list.Action) => action.payload as list.PAYLOAD_DONE_FETCH_CATEGORY_LIST;

export const reducer = (state: State = defaultState, action: list.Action): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case list.DONE_FETCH_CATEGORY_LIST:
      return replace(newState, action);

    default:
      return newState;
  }
};
