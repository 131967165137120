import { combineReducers } from 'redux';
import * as layout from './layout';
import * as pane from './pane';
import * as session from './session';

export type State = {
  layout: layout.State;
  pane: pane.State;
  session: session.State;
};

export const defaultState = {
  layout: layout.defaultState,
  pane: pane.defaultState,
  session: session.defaultState,
};

export const reducer = combineReducers<State>({
  layout: layout.reducer,
  pane: pane.reducer,
  session: session.reducer,
});
