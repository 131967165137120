import React from 'react';
import Link from 'next/link';
import JsonLd from '../common/JsonLd';
import * as Layout from '../../domain/Layout';

/**
 * パンくずリストの子要素
 */
interface ItemProps {
  label: string;
  href: string;
  as: string;
  isLast: boolean;
}

const BreadcrumbItem: React.FC<ItemProps> = props => {
  const { label, href, as, isLast } = props;

  // 最後の要素はリンクをつけない
  if (isLast) {
    return <li className="flex items-center">{label}</li>;
  }

  return (
    <li className="flex items-center">
      <Link href={href} as={as}>
        <a className="text-blue-500 font-bold">{label}</a>
      </Link>
      <svg className="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
      </svg>
    </li>
  );
};

/**
 * パンくずリスト
 */
interface Props {
  layout: Layout.Value;
}

type BreadcrumbList = Breadcrumb[];
type Breadcrumb = {
  name: string;
  href: string;
  as: string;
};

export default class BreadCrumb extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { layout } = this.props;
    const breadcrumb = layout.breadcrumb.list();
    return (
      <div className="flex w-11/12 lg:w-10/12 whitespace-no-wrap overflow-y-hidden ml-auto mr-auto text-xs text-black my-3" aria-label="Breadcrumb">
        <ol className="list-none p-0 inline-flex">{this.renderNodes(breadcrumb)}</ol>
        <JsonLd data={this.makeJsonLdData(breadcrumb)} />
      </div>
    );
  }

  private renderNodes(breadcrumb: BreadcrumbList): React.ReactNode {
    return breadcrumb.map((item: Breadcrumb, index: number) => {
      return (
        <BreadcrumbItem key={`breadcrumb_${index + 1}`} href={item.href} as={item.as} label={item.name} isLast={index === breadcrumb.length - 1} />
      );
    });
  }

  private makeJsonLdData(breadcrumb: BreadcrumbList) {
    // 構造化スニペットにはトップの情報は記載しないので先頭を削除する
    breadcrumb.shift();

    const itemListElement = breadcrumb.map((item: Breadcrumb, index: number) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': item.as,
          name: item.name,
        },
      };
    });

    return {
      '@context': 'http://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement,
    };
  }
}
