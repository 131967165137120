import immutable from 'immutable';

type ViolationValue = {
  target: Type.Target;
  targetId: number;
  code: number;
  reason: string;
};

const defaultValue = {
  target: 'topics' as Type.Target,
  targetId: 0,
  code: 0,
  reason: '',
};

const ValueRecord: immutable.Record.Factory<ViolationValue> = immutable.Record(defaultValue);
export class Value extends ValueRecord {
  /**
   * @param {number} topicId
   * @param {number} commentId
   * @returns {Value}
   */
  public static generate(topicId: number, commentId?: number): Value {
    // コメントID があるときはコメント用の違反報告
    if (commentId) {
      return new Value({ target: 'comments', targetId: commentId });
    }

    return new Value({ target: 'topics', targetId: topicId });
  }

  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.targetId === 0 && this.code === 0 && this.reason === '';
  }
}
