import immutable from 'immutable';
import * as Validation from '../../domain/Validation';
import * as detail from '../comments/detail';

/**
 * reducer
 */
export type State = immutable.Map<number, Validation.Value>;

export const defaultState = immutable.Map<number, Validation.Value>();

const merge = (state: State, action: detail.FailureConfirmComment) => {
  const payload = action.payload as detail.PAYLOAD_FAILURE_CONFIRM_COMMENT;
  return state.set(payload.commentId, payload.validation);
};

export const reducer = (state: State = defaultState, action: detail.Action) => {
  const newState = state.toJS ? state : immutable.fromJS(state);

  switch (action.type) {
    case detail.SUCCESS_CONFIRM_COMMENT:
      return defaultState;

    case detail.FAILURE_CONFIRM_COMMENT:
      return merge(newState, action as detail.FailureConfirmComment);

    default:
      return newState;
  }
};
