import React from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { NextContext, NextStatelessComponent } from 'next';
import Router from 'next/router';
import App, { Container, AppComponentProps, AppComponentContext } from 'next/app';
import withRedux from 'next-redux-wrapper';
import makeStore, { RootState } from '../store';
import { CookieConstant } from '../constants/constant';
import Utils from '../libs/utils';
import Logger from '../libs/logger';
import cookies from '../libs/cookies';
import SessionActionDispatcher from '../modules/commons/SessionActionDispatcher';
import PaneActionDispatcher from '../modules/commons/PaneActionDispatcher';
import CategoryActionDispatcher from '../modules/categories/ActionDispatcher';
import Layout from '../components/Layout';

declare var tracker: any;
declare var dataLayer: any;

interface Props extends AppComponentProps {
  isServer: boolean;
  isSp: boolean;
  isErr: boolean;
  store: Store<RootState>;
}

class MyApp extends App<Props> {
  public static async getInitialProps(context: AppComponentContext) {
    const ctx = context.ctx as NextContext;
    const { req, isServer, store, err } = ctx;
    const cookie = cookies(ctx);

    if (err) {
      Logger.error(err);
    }

    const ua = req ? req.headers['user-agent'] : navigator.userAgent;
    const isSp = Utils.isSp(ua);
    ctx.isSp = isSp;
    ctx.loginSessionToken = cookie.get(CookieConstant.KEY_ACCOUNT_LOGIN);
    ctx.isGoogleBot = Utils.isGoogleBot(ua);

    // ページ共通データ取得して、ストアへ保存（最初のアクセスのみ）
    if (isServer) {
      const sessionActionDispatcher = new SessionActionDispatcher(store.dispatch);
      const paneActionDispatcher = new PaneActionDispatcher(store.dispatch);

      await Promise.all([sessionActionDispatcher.createSession(ctx), paneActionDispatcher.fetch(ctx.loginSessionToken, ctx.isSp)]);

      // マスタ系データ取得
      const categoryActionDispatcher = new CategoryActionDispatcher(store.dispatch, store.getState().commons.session);
      await categoryActionDispatcher.fetchList();
    }

    // 各ページの getInitialProps 実行
    let pageProps: any = {};
    const Component = context.Component as NextStatelessComponent;
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return {
      pageProps,
      isServer,
      isSp,
      isErr: err ? true : false,
    };
  }

  /**
   * client で発生したエラーのハンドリング
   * SSR で発生した場合はキャッチできない
   *
   * @returns {void}
   */
  public componentDidCatch(error: Error, errorInfo: any): void {
    Logger.error(error);
    throw error;
  }

  public componentDidMount(): void {
    // ルータイベント定義
    Router.onRouteChangeComplete = () => {
      // SPA遷移時に GTM へ PV として送信する
      if ('dataLayer' in window) {
        dataLayer.push({ event: 'pageview' });
      }

      // SPA遷移時に KARTE へ送信する
      if ('tracker' in window) {
        tracker.view();
      }
    };
  }

  public render(): React.ReactNode {
    const { Component, pageProps, store } = this.props;
    let { isServer, isSp } = this.props;
    let isErr = false;
    if (typeof pageProps !== 'undefined' && pageProps.isErr) {
      isErr = true;
    }

    // クライアント側で何かしらのエラーが発生した場合、getInitialProps で渡している props が渡ってこないので store から取得する
    if (typeof isSp === 'undefined') {
      isSp = store.getState().commons.session.isSp;
      isServer = false;
      isErr = true;
    }

    return (
      <Container>
        <Provider store={store}>
          <Layout {...pageProps} isServer={isServer} isSp={isSp} isErr={isErr}>
            <Component {...pageProps} isSp={isSp} />
          </Layout>
        </Provider>
      </Container>
    );
  }
}

export default withRedux(makeStore)(MyApp);
