import { NextContext } from 'next';
import { actionCreators, Action } from './session';
import Utils from '../../libs/utils';
import * as Session from '../../domain/Session';
import * as User from '../../domain/User';
import RepositoryFactory from '../../repositories/RepositoryFactory';
import AuthRepository from '../../repositories/AuthRepository';

/**
 * セッションのアクション
 */
export default class SessionActionDispatcher {
  private dispatch: (action: Action) => Action;
  private repository: AuthRepository;

  constructor(dispatch: (action: Action) => Action) {
    this.dispatch = dispatch;
    this.repository = RepositoryFactory.createAuthRepository();
  }

  /**
   * コンテキストからセッションを生成して保存する
   *
   * @param {NextContext} ctx
   * @returns {Promise<void>}
   */
  public async createSession(ctx: NextContext): Promise<void> {
    const { loginSessionToken, isSp, isGoogleBot } = ctx;
    const verifiedLoginSessionToken = await this.repository.findVerifyResultLoginSessionToken(loginSessionToken);
    const accessToken = verifiedLoginSessionToken === '' ? await this.fetchGuestToken() : this.extractAccessToken(verifiedLoginSessionToken);
    const session = new Session.Value({
      isSp,
      loginSessionToken: verifiedLoginSessionToken,
      accessToken,
      isGoogleBot,
    });

    const user = await this.fetchUser(session);
    this.dispatch(actionCreators.doneCreateSession(session.set('user', user)));
  }

  /**
   * ゲスト用のアクセストークンを発行して保存する
   *
   * @returns {Promise<string>}
   */
  private async fetchGuestToken(): Promise<string> {
    return this.repository.createGuestToken();
  }

  /**
   * ログインセッショントークンからアクセストークンを取り出す
   *
   * @param {string} loginSessionToken
   * @returns {string}
   */
  private extractAccessToken(loginSessionToken: string): string {
    return Utils.decodeJwtToken<{ tokens: { access_token: string } }>(loginSessionToken).tokens.access_token;
  }

  /**
   * 接続ユーザ情報を取得する
   *
   * @param {Session.Value} session
   * @returns {Promise<User.Entity>}
   */
  private fetchUser(session: Session.Value): Promise<User.Entity> {
    if (session.isGuest()) {
      return Promise.resolve(new User.Entity());
    }

    const repository = RepositoryFactory.createUserRepository(session);
    return repository.find();
  }
}
