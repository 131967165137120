import { Action as ReduxAction, createAction } from 'redux-actions';
import * as Topic from '../../domain/Topic';
import * as Validation from '../../domain/Validation';

/**
 * action
 */
export const INIT_CONFIRM_TOPIC = 'INIT_CONFIRM_TOPIC';
export const REQUEST_CONFIRM_TOPIC = 'REQUEST_CONFIRM_TOPIC';
export const SUCCESS_CONFIRM_TOPIC = 'SUCCESS_CONFIRM_TOPIC';
export const FAILURE_CONFIRM_TOPIC = 'FAILURE_CONFIRM_TOPIC';
export const DONE_POST_TOPIC = 'DONE_POST_TOPIC';

export type PAYLOAD_REQUEST_CONFIRM_TOPIC = Topic.Value;
export type PAYLOAD_FAILURE_CONFIRM_TOPIC = Validation.Value;
export type PAYLOAD_DONE_POST_TOPIC = Topic.Entity;

export interface InitConfirmTopic extends ReduxAction<void> {}
export interface RequestConfirmTopic extends ReduxAction<PAYLOAD_REQUEST_CONFIRM_TOPIC> {}
export interface SuccessConfirmTopic extends ReduxAction<void> {}
export interface FailureConfirmTopic extends ReduxAction<PAYLOAD_FAILURE_CONFIRM_TOPIC> {}
export interface DonePostTopic extends ReduxAction<PAYLOAD_DONE_POST_TOPIC> {}

export type Payload = PAYLOAD_REQUEST_CONFIRM_TOPIC | PAYLOAD_FAILURE_CONFIRM_TOPIC | PAYLOAD_DONE_POST_TOPIC;

export type Action = InitConfirmTopic | RequestConfirmTopic | SuccessConfirmTopic | FailureConfirmTopic | DonePostTopic;

export const actionCreators = {
  initConfirmTopic: createAction(INIT_CONFIRM_TOPIC),
  requestConfirmTopic: createAction<PAYLOAD_REQUEST_CONFIRM_TOPIC>(REQUEST_CONFIRM_TOPIC),
  successConfirmTopic: createAction(SUCCESS_CONFIRM_TOPIC),
  failureConfirmTopic: createAction<PAYLOAD_FAILURE_CONFIRM_TOPIC>(FAILURE_CONFIRM_TOPIC),
  donePostTopic: createAction<PAYLOAD_DONE_POST_TOPIC>(DONE_POST_TOPIC),
};

/**
 * reducer
 */
export type State = Topic.Value;

export const defaultState = new Topic.Value();

const init = (state: object) => new Topic.Value(state);
const replace = (state: State, action: RequestConfirmTopic) => action.payload as PAYLOAD_REQUEST_CONFIRM_TOPIC;

export const reducer = (state: State = defaultState, action: Action): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case REQUEST_CONFIRM_TOPIC:
      return replace(newState, action as RequestConfirmTopic);

    case DONE_POST_TOPIC:
      return defaultState;

    default:
      return newState;
  }
};
