import { combineReducers } from 'redux';
import * as comment from './comment';
import * as search from './search';
import * as topic from './topic';
import * as violation from './violation';

export type State = {
  comment: comment.State;
  search: search.State;
  topic: topic.State;
  violation: violation.State;
};

export const defaultState = {
  comment: comment.defaultState,
  search: search.defaultState,
  topic: topic.defaultState,
  violation: violation.defaultState,
};

export const reducer = combineReducers<State>({
  comment: comment.reducer,
  search: search.reducer,
  topic: topic.reducer,
  violation: violation.reducer,
});
