import { Action as ReduxAction, createAction } from 'redux-actions';
import immutable from 'immutable';
import * as Category from '../../domain/Category';

/**
 * action
 */
export const DONE_FETCH_CATEGORY_LIST = 'DONE_FETCH_CATEGORY_LIST';

export type PAYLOAD_DONE_FETCH_CATEGORY_LIST = Category.EntityList;

export interface DoneFetchCategoryListAction extends ReduxAction<PAYLOAD_DONE_FETCH_CATEGORY_LIST> {}

export type Action = DoneFetchCategoryListAction;

export type Payload = PAYLOAD_DONE_FETCH_CATEGORY_LIST;

export const actionCreators = {
  doneFetchCategoryList: createAction<Payload>(DONE_FETCH_CATEGORY_LIST),
};

/**
 * reducer
 */
export type State = immutable.List<number>;

export const defaultState = immutable.List<number>();

const init = (state: State) => immutable.List(state);

const create = (state: State, action: DoneFetchCategoryListAction) => {
  const payload = action.payload as PAYLOAD_DONE_FETCH_CATEGORY_LIST;
  return payload.result.toList();
};

export const reducer = (state: State = defaultState, action: Action): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case DONE_FETCH_CATEGORY_LIST:
      return create(newState, action);

    default:
      return newState;
  }
};
