import { Action as ReduxAction, createAction } from 'redux-actions';
import * as Topic from '../../domain/Topic';
import * as MyComment from '../../domain/MyComment';

/**
 * action
 */
export const DONE_FETCH_MY_LIST = 'DONE_FETCH_MY_LIST';

export type PAYLOAD_DONE_FETCH_MY_LIST = {
  clips: Topic.EntityList;
  topics: Topic.EntityList;
  comments: MyComment.EntityList;
};

export interface DoneFetchMyList extends ReduxAction<PAYLOAD_DONE_FETCH_MY_LIST> {}

export type Payload = PAYLOAD_DONE_FETCH_MY_LIST;

export type Action = DoneFetchMyList;

export const actionCreators = {
  doneFetchMyList: createAction<PAYLOAD_DONE_FETCH_MY_LIST>(DONE_FETCH_MY_LIST),
};

/**
 * reducer
 */
export type State = PAYLOAD_DONE_FETCH_MY_LIST;

export const defaultState = {
  clips: new Topic.EntityList(),
  topics: new Topic.EntityList(),
  comments: new MyComment.EntityList(),
};

const init = (state: PAYLOAD_DONE_FETCH_MY_LIST) => ({
  clips: Topic.EntityList.fromJS(state.clips),
  topics: Topic.EntityList.fromJS(state.topics),
  comments: MyComment.EntityList.fromJS(state.comments),
});

const replace = (state: State, action: DoneFetchMyList) => action.payload as Payload;

export const reducer = (state: State = defaultState, action: Action): State => {
  const newState = state.clips.toJS ? state : init(state);

  switch (action.type) {
    case DONE_FETCH_MY_LIST:
      return replace(newState, action);

    default:
      return newState;
  }
};
