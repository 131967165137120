import React from 'react';
import { connect } from 'react-redux';
import immutable from 'immutable';
import { ThemeProvider } from 'styled-components';
import { mainTheme } from '../styles/theme';
import { RootState } from '../store';
import * as Layout from '../domain/Layout';
import Seo from './layout/Seo';
import PaneHtml from './layout/PaneHtml';
import Breadcrumb from './layout/Breadcrumb';
import PostContainer from '../components/top/post/Container';

/**
 * 共通レイアウトコンポーネント
 */
interface Props {
  isServer: boolean;
  isSp: boolean;
  isErr: boolean;
  layout: Layout.Value;
  pane: immutable.Map<string, string>;
}

class LayoutComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { isServer, isSp, layout, pane } = this.props;
    mainTheme.isSp = isSp;

    return (
      <ThemeProvider theme={mainTheme}>
        <div>
          <PaneHtml html={pane.get('header')} />
          <Seo seo={layout.seo} isServer={isServer} />
          {isSp ? this.renderSpContents() : this.renderPcContents()}
          <PaneHtml html={pane.get('footer')} />
        </div>
      </ThemeProvider>
    );
  }

  /**
   * SP用表示
   *
   * @returns {React.ReactNode}
   */
  private renderSpContents(): React.ReactNode {
    const { layout, children } = this.props;
    return (
      <div>
        {layout.header.isTop || !layout.header.isNewHeader ? '' : <Breadcrumb layout={layout} />}
        {children}
        {layout.footer.showPostButton ? <PostContainer /> : ''}
      </div>
    );
  }

  /**
   * PC用表示
   *
   * @returns {React.ReactNode}
   */
  private renderPcContents(): React.ReactNode {
    const { isErr, layout, pane, children } = this.props;

    return (
      <div id="contents-wrapper">
        <div id="contents">
          {layout.header.isTop || !layout.header.isNewHeader ? '' : <Breadcrumb layout={layout} />}
          <style>
            {`
              .main {
                display: flex;
                margin: 16px;
              }
              #contents { width: 100%; }
            `}
          </style>
          <div className="lg:flex">
            {children}
            {isErr ? (
              ''
            ) : (
              <aside className="lg:w-3/12 pt-3 pb-3 px-2 lg:mr-auto">
                <PaneHtml html={pane.get('side')} />
              </aside>
            )}
          </div>
          {layout.footer.showPostButton ? <PostContainer /> : ''}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    layout: state.commons.layout,
    pane: state.commons.pane,
  };
};

export default connect(mapStateToProps)(LayoutComponent);
