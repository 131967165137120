import { Action as ReduxAction, createAction } from 'redux-actions';
import * as Violation from '../../domain/Violation';
import * as Validation from '../../domain/Validation';

/**
 * action
 */
export const INIT_CONFIRM_VIOLATION = 'INIT_CONFIRM_VIOLATION';
export const REQUEST_CONFIRM_VIOLATION = 'REQUEST_CONFIRM_VIOLATION';
export const SUCCESS_CONFIRM_VIOLATION = 'SUCCESS_CONFIRM_VIOLATION';
export const FAILURE_CONFIRM_VIOLATION = 'FAILURE_CONFIRM_VIOLATION';
export const DONE_POST_VIOLATION = 'DONE_POST_VIOLATION';

export type PAYLOAD_INIT_CONFIRM_VIOLATION = Violation.Value;
export type PAYLOAD_REQUEST_CONFIRM_VIOLATION = Violation.Value;
export type PAYLOAD_FAILURE_CONFIRM_VIOLATION = Validation.Value;
export type PAYLOAD_DONE_POST_VIOLATION = Violation.Value;

export interface InitConfirmViolation extends ReduxAction<PAYLOAD_INIT_CONFIRM_VIOLATION> {}
export interface RequestConfirmViolation extends ReduxAction<PAYLOAD_REQUEST_CONFIRM_VIOLATION> {}
export interface SuccessConfirmViolation extends ReduxAction<void> {}
export interface FailureConfirmViolation extends ReduxAction<PAYLOAD_FAILURE_CONFIRM_VIOLATION> {}
export interface DonePostViolation extends ReduxAction<PAYLOAD_DONE_POST_VIOLATION> {}

export type Payload =
  | PAYLOAD_INIT_CONFIRM_VIOLATION
  | PAYLOAD_REQUEST_CONFIRM_VIOLATION
  | PAYLOAD_FAILURE_CONFIRM_VIOLATION
  | PAYLOAD_DONE_POST_VIOLATION;

export type Action = InitConfirmViolation | RequestConfirmViolation | SuccessConfirmViolation | FailureConfirmViolation | DonePostViolation;

export const actionCreators = {
  initConfirmViolation: createAction<PAYLOAD_INIT_CONFIRM_VIOLATION>(INIT_CONFIRM_VIOLATION),
  requestConfirmViolation: createAction<PAYLOAD_REQUEST_CONFIRM_VIOLATION>(REQUEST_CONFIRM_VIOLATION),
  successConfirmViolation: createAction(SUCCESS_CONFIRM_VIOLATION),
  failureConfirmViolation: createAction<PAYLOAD_FAILURE_CONFIRM_VIOLATION>(FAILURE_CONFIRM_VIOLATION),
  donePostViolation: createAction<PAYLOAD_DONE_POST_VIOLATION>(DONE_POST_VIOLATION),
};

/**
 * reducer
 */
export type State = Violation.Value;

export const defaultState = new Violation.Value();

const init = (state: object) => new Violation.Value(state);
const replace = (state: State, action: RequestConfirmViolation) => action.payload as PAYLOAD_REQUEST_CONFIRM_VIOLATION;

export const reducer = (state: State = defaultState, action: Action) => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case INIT_CONFIRM_VIOLATION:
    case REQUEST_CONFIRM_VIOLATION:
      return replace(newState, action as InitConfirmViolation | RequestConfirmViolation);

    case DONE_POST_VIOLATION:
      return defaultState;

    default:
      return newState;
  }
};
