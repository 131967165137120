import { combineReducers } from 'redux';
import * as clip from './clip';
import * as comment from './comment';
import * as top from './top';
import * as topic from './topic';

export type State = {
  top: top.State;
  clip: clip.State;
  topic: topic.State;
  comment: comment.State;
};

export const defaultState = {
  top: top.defaultState,
  clip: clip.defaultState,
  topic: topic.defaultState,
  comment: comment.defaultState,
};

export const reducer = combineReducers<State>({
  top: top.reducer,
  clip: clip.reducer,
  topic: topic.reducer,
  comment: comment.reducer,
});
