import { Action as ReduxAction, createAction } from 'redux-actions';
import * as Session from '../../domain/Session';

/**
 * action
 */
export const DONE_CREATE_SESSION = 'DONE_CREATE_SESSION';

export type PAYLOAD_DONE_CREATE_SESSION = Session.Value;

export interface DoneCreateSession extends ReduxAction<PAYLOAD_DONE_CREATE_SESSION> {}

export type Action = DoneCreateSession;

export type Payload = PAYLOAD_DONE_CREATE_SESSION;

export const actionCreators = {
  doneCreateSession: createAction<PAYLOAD_DONE_CREATE_SESSION>(DONE_CREATE_SESSION),
};

/**
 * reducer
 */
export type State = Session.Value;

export const defaultState = new Session.Value();

const init = (state: object) => Session.Value.fromJS(state);
const replace = (state: State, action: Action) => action.payload as Payload;

export const reducer = (state: State = defaultState, action: Action): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case DONE_CREATE_SESSION:
      return replace(newState, action);

    default:
      return newState;
  }
};
