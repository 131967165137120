import React from 'react';
import Head from 'next/head';
import * as Seo from '../../domain/Seo';

/**
 * ラウンジ用の SEO 設定
 */
interface Props {
  seo: Seo.Value;
  isServer: boolean;
}

const SeoComponent: React.FC<Props> = props => {
  const { seo, isServer } = props;
  if (!isServer) {
    document.title = seo.meta.title;
  }

  // Flexible Sampling（SEO対策）の設定
  const paywall = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'ナース専科',
    isAccessibleForFree: 'true',
    hasPart: {
      '@type': 'WebPageElement',
      isAccessibleForFree: 'true',
      cssSelector: '.google-paywall',
    },
  };

  return (
    <Head>
      <title>{seo.meta.title}</title>
      <meta key="keywords" name="keywords" content={seo.meta.keywords} />
      <meta key="description" name="description" content={seo.meta.description} />
      {seo.meta.noindex && <meta name="robots" content="noindex" />}
      <meta key="ogTitle" property="og:title" content={seo.ogp.title} />
      <meta key="ogType" property="og:type" content={seo.ogp.type} />
      <meta key="ogSiteName" property="og:site_name" content={seo.ogp.siteName} />
      <meta key="ogDescription" property="og:description" content={seo.ogp.description} />
      <meta key="ogUrl" property="og:url" content={seo.ogp.url} />
      <meta key="ogImage" property="og:image" content={seo.ogp.image} />
      {seo.link.canonical && <link rel="canonical" href={seo.link.canonical} />}
      {seo.link.prev && <link rel="prev" href={seo.link.prev} />}
      {seo.link.next && <link rel="next" href={seo.link.next} />}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(paywall) }} />
    </Head>
  );
};

export default SeoComponent;
