import { Action as ReduxAction, createAction } from 'redux-actions';
import * as Topic from '../../domain/Topic';

/**
 * action
 */
export const DONE_SEND_TOPIC_HEART = 'DONE_SEND_TOPIC_HEART';
export const DONE_TOGGLE_CLIP = 'DONE_TOGGLE_CLIP';
export const DONE_FETCH_TOPIC = 'DONE_FETCH_TOPIC';
export const DONE_DISABLED_POST_COMMENT = 'DONE_DISABLED_POST_COMMENT';

export type PAYLOAD_DONE_SEND_TOPIC_HEART = Topic.Entity;
export type PAYLOAD_DONE_TOGGLE_CLIP = Topic.Entity;
export type PAYLOAD_DONE_FETCH_TOPIC = Topic.Entity;
export type PAYLOAD_DONE_DISABLED_POST_COMMENT = Topic.Entity;

export interface DoneSendTopicHeart extends ReduxAction<PAYLOAD_DONE_SEND_TOPIC_HEART> {}
export interface DoneToggleClip extends ReduxAction<PAYLOAD_DONE_TOGGLE_CLIP> {}
export interface DoneFetchTopic extends ReduxAction<PAYLOAD_DONE_FETCH_TOPIC> {}
export interface DoneDisabledPostComment extends ReduxAction<PAYLOAD_DONE_DISABLED_POST_COMMENT> {}

export type Payload = PAYLOAD_DONE_SEND_TOPIC_HEART | PAYLOAD_DONE_TOGGLE_CLIP | PAYLOAD_DONE_FETCH_TOPIC | PAYLOAD_DONE_DISABLED_POST_COMMENT;

export type Action = DoneSendTopicHeart | DoneToggleClip | DoneFetchTopic | DoneDisabledPostComment;

export const actionCreators = {
  doneSendTopicHeart: createAction<PAYLOAD_DONE_SEND_TOPIC_HEART>(DONE_SEND_TOPIC_HEART),
  doneToggleClip: createAction<PAYLOAD_DONE_TOGGLE_CLIP>(DONE_TOGGLE_CLIP),
  doneFetchTopic: createAction<PAYLOAD_DONE_FETCH_TOPIC>(DONE_FETCH_TOPIC),
  doneDisabledPostComment: createAction<PAYLOAD_DONE_DISABLED_POST_COMMENT>(DONE_DISABLED_POST_COMMENT),
};
