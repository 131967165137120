import { Action } from 'redux';
import * as Validation from '../../domain/Validation';

/**
 * reducer
 */
export type State = Validation.Value;

export const defaultState = new Validation.Value();

export const reducer = (state: State = defaultState, action: Action) => {
  const newState = state.toJS ? state : new Validation.Value(state);

  return newState;
};
