import { Action as ReduxAction, createAction } from 'redux-actions';
import * as Comment from '../../domain/Comment';
import * as Validation from '../../domain/Validation';

/**
 * action
 */
export const SUCCESS_CONFIRM_COMMENT = 'SUCCESS_CONFIRM_COMMENT';
export const FAILURE_CONFIRM_COMMENT = 'FAILURE_CONFIRM_COMMENT';
export const DONE_SEND_COMMENT_HEART = 'DONE_SEND_COMMENT_HEART';

export type PAYLOAD_FAILURE_CONFIRM_COMMENT = { commentId: number; validation: Validation.Value };
export type PAYLOAD_DONE_SEND_COMMENT_HEART = Comment.Entity;

export interface SuccessConfirmComment extends ReduxAction<void> {}
export interface FailureConfirmComment extends ReduxAction<PAYLOAD_FAILURE_CONFIRM_COMMENT> {}
export interface DoneSendCommentHeart extends ReduxAction<PAYLOAD_DONE_SEND_COMMENT_HEART> {}

export type Payload = PAYLOAD_FAILURE_CONFIRM_COMMENT | PAYLOAD_DONE_SEND_COMMENT_HEART;

export type Action = SuccessConfirmComment | FailureConfirmComment | DoneSendCommentHeart;

export const actionCreators = {
  successConfirmComment: createAction(SUCCESS_CONFIRM_COMMENT),
  failureConfirmComment: createAction<PAYLOAD_FAILURE_CONFIRM_COMMENT, number, Validation.Value>(
    FAILURE_CONFIRM_COMMENT,
    (commentId, validation) => ({ commentId, validation }),
  ),
  doneSendCommentHeart: createAction<PAYLOAD_DONE_SEND_COMMENT_HEART>(DONE_SEND_COMMENT_HEART),
};
