import * as Topic from '../../domain/Topic';
import * as top from '../top/topic';
import * as detail from '../topics/detail';
import * as list from '../topics/list';
import * as form from '../topics/form';
import * as violation from '../violations/form';
import * as myClip from '../my/clip';
import * as myTopic from '../my/topic';

export type State = Topic.EntityList;

export const defaultState = new Topic.EntityList();

const init = (state: object) => Topic.EntityList.fromJS(state);
const mergeEntity = (state: State, action: detail.Action | form.DonePostTopic) => state.replaceEntity(action.payload as Topic.Entity);

const mergeEntityList = (state: State, action: top.Action | list.Action | myClip.Action | myTopic.Action) => {
  return state.mergeList(action.payload as list.PAYLOAD_DONE_FETCH_TOPIC_LIST);
};

const mergeViolation = (state: State, action: violation.DonePostViolation) => {
  const payload = action.payload as violation.PAYLOAD_DONE_POST_VIOLATION;
  if (payload.target === 'topics') {
    const entity = state.getEntity(payload.targetId);
    return state.replaceEntity(entity.sendedViolation());
  }

  return state;
};

export const reducer = (
  state: State = defaultState,
  action: detail.Action | top.Action | list.Action | myClip.Action | myTopic.Action | violation.Action,
): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case detail.DONE_SEND_TOPIC_HEART:
    case detail.DONE_TOGGLE_CLIP:
    case detail.DONE_FETCH_TOPIC:
    case detail.DONE_DISABLED_POST_COMMENT:
      return mergeEntity(newState, action as detail.Action);

    case top.DONE_FETCH_TOP_TOPIC_LIST:
    case list.DONE_FETCH_TOPIC_LIST:
    case myClip.DONE_FETCH_MY_CLIP_LIST:
    case myTopic.DONE_FETCH_MY_TOPIC_LIST:
      return mergeEntityList(newState, action as
        | top.DoneFetchTopTopicList
        | list.DoneFetchTopicList
        | myClip.DoneFetchMyClipList
        | myTopic.DoneFetchMyTopicList);

    case violation.DONE_POST_VIOLATION:
      return mergeViolation(newState, action as violation.DonePostViolation);

    default:
      return newState;
  }
};
