import { combineReducers } from 'redux';
import * as list from './list';
import * as form from './form';

export type State = {
  list: list.State;
  form: form.State;
};

export const defaultState = {
  list: list.defaultState,
  form: form.defaultState,
};

export const reducer = combineReducers<State>({
  list: list.reducer,
  form: form.reducer,
});
