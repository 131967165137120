import React from 'react';

/**
 * api-common-pane から取得した html の管理
 * SSR 時はスクリプトが実行されるが、 SPA 時ではスクリプトが実行されないので
 * マウント時に eval で無理やりスクリプトを実行する
 */
interface Props {
  html?: string;
}

interface State {
  extractScripts: string[];
  html: string;
}

export default class PaneHtml extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      extractScripts: [],
      html: '',
    };
  }

  public componentWillMount(): void {
    const { html } = this.props;
    if (!html) {
      return;
    }

    // html からスクリプトを抽出し、二重実行しないように元の html からスクリプトを削除する
    const extractScripts = html.match(/<script>[\s\S]*?<\/script>/gi) || [];
    const removedScriptHtml = extractScripts.reduce((html: string, extractScript: string) => {
      return html.replace(extractScript, '');
    }, html);

    this.setState({
      extractScripts,
      html: removedScriptHtml,
    });
  }

  public componentDidMount(): void {
    // スクリプトを実行する
    const length = this.state.extractScripts.length;
    for (let i = 0; i < length; i += 1) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = this.state.extractScripts[i].slice(8, -9);
      document.body.appendChild(script);
    }
  }

  public render(): React.ReactNode {
    return <div dangerouslySetInnerHTML={{ __html: this.state.html }} />;
  }
}
