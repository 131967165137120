import React from 'react';
import Router from '../../../libs/router';
import Utils from '../../../libs/utils';
import * as Session from '../../../domain/Session';

/**
 * 投稿ボタンルートコンポーネント
 */
interface Props {
  session: Session.Value;
}

export default class PostComponent extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick(): void {
    if (this.props.session.isGuest()) {
      Router.redirect(Utils.createAuhorizeUrl('/topic/form'));
    } else {
      Router.pushScrollTo('/topic/form');
    }
  }

  public render(): React.ReactNode {
    return (
      <div className="fixed w-full bottom-0 pb-3">
        <button
          onClick={this.handleClick}
          className="block w-10/12 bg-pink-500 border-solid border-2 border-pink-500 hover:border-pink-500 rounded text-white font-bold mr-auto ml-auto py-2"
        >
          ＋ トピックを投稿する
        </button>
      </div>
    );
  }
}
