import { NextContext } from 'next';
import Cookies from 'universal-cookie';

export default (ctx: NextContext) => {
  if (ctx.req) {
    const { cookie = {} } = ctx.req.headers;
    return new Cookies(cookie);
  }

  return new Cookies();
};
