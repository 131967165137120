import { combineReducers } from 'redux';
import * as categories from './categories';
import * as comments from './comments';
import * as mycomments from './mycomments';
import * as topics from './topics';

export type State = {
  categories: categories.State;
  comments: comments.State;
  mycomments: mycomments.State;
  topics: topics.State;
};

export const defaultState = {
  categories: categories.defaultState,
  topics: topics.defaultState,
  comments: comments.defaultState,
  mycomments: mycomments.defaultState,
};

export const reducer = combineReducers<State>({
  categories: categories.reducer,
  topics: topics.reducer,
  comments: comments.reducer,
  mycomments: mycomments.reducer,
});
