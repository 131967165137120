import * as MyComment from '../../domain/MyComment';
import * as my from '../my/comment';

/**
 * reducer
 */
export type State = MyComment.EntityList;

export const defaultState = new MyComment.EntityList();

const init = (state: object) => MyComment.EntityList.fromJS(state);
const mergeEntityList = (state: State, action: my.Action) => state.mergeList(action.payload as my.PAYLOAD_DONE_FETCH_MY_COMMENT_LIST);

export const reducer = (state: State = defaultState, action: my.Action): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case my.DONE_FETCH_MY_COMMENT_LIST:
      return mergeEntityList(newState, action);

    default:
      return newState;
  }
};
