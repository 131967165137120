import { Action as ReduxAction, createAction } from 'redux-actions';
import immutable from 'immutable';

/**
 * action
 */
export const DONE_FETCH_PANE = 'DONE_FETCH_PANE';

export type PAYLOAD_DONE_FETCH_PANE = immutable.Map<string, string>;

export interface DoneFetchPane extends ReduxAction<PAYLOAD_DONE_FETCH_PANE> {}

export type Action = DoneFetchPane;

export type Payload = PAYLOAD_DONE_FETCH_PANE;

export const actionCreators = {
  doneFetchPane: createAction<PAYLOAD_DONE_FETCH_PANE, string, string, string>(DONE_FETCH_PANE, (header, footer, side) => {
    return immutable.Map({ header, footer, side });
  }),
};

/**
 * reducer
 */
export type State = immutable.Map<string, string>;

export const defaultState = immutable.Map<string, string>();

const init = (state: object) => immutable.fromJS(state);
const replace = (state: State, action: Action) => action.payload as Payload;

export const reducer = (state: State = defaultState, action: Action): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case DONE_FETCH_PANE:
      return replace(newState, action);

    default:
      return newState;
  }
};
