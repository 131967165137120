import * as Validation from '../../domain/Validation';
import * as form from '../violations/form';

/**
 * reducer
 */
export type State = Validation.Value;

export const defaultState = new Validation.Value();

const replace = (state: State, action: form.FailureConfirmViolation) => action.payload as form.PAYLOAD_FAILURE_CONFIRM_VIOLATION;

export const reducer = (state: State = defaultState, action: form.Action) => {
  const newState = state.toJS ? state : new Validation.Value(state);

  switch (action.type) {
    case form.INIT_CONFIRM_VIOLATION:
    case form.SUCCESS_CONFIRM_VIOLATION:
      return defaultState;

    case form.FAILURE_CONFIRM_VIOLATION:
      return replace(newState, action as form.FailureConfirmViolation);

    default:
      return newState;
  }
};
