import { combineReducers } from 'redux';
import * as categories from './categories';
import * as comments from './comments';
import * as commons from './commons';
import * as entities from './entities';
import * as my from './my';
import * as top from './top';
import * as topics from './topics';
import * as validations from './validations';
import * as violations from './violations';

export type State = {
  categories: categories.State;
  comments: comments.State;
  commons: commons.State;
  entities: entities.State;
  my: my.State;
  top: top.State;
  topics: topics.State;
  validations: validations.State;
  violations: violations.State;
};

export const defaultState = {
  categories: categories.defaultState,
  comments: comments.defaultState,
  commons: commons.defaultState,
  entities: entities.defaultState,
  my: my.defaultState,
  top: top.defaultState,
  topics: topics.defaultState,
  validations: validations.defaultState,
  violations: violations.defaultState,
};

export default combineReducers<State>({
  entities: entities.reducer,
  commons: commons.reducer,
  categories: categories.reducer,
  top: top.reducer,
  topics: topics.reducer,
  comments: comments.reducer,
  violations: violations.reducer,
  validations: validations.reducer,
  my: my.reducer,
});
