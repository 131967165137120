import { combineReducers } from 'redux';
import * as list from './list';

export type State = {
  list: list.State;
};

export const defaultState = {
  list: list.defaultState,
};

export const reducer = combineReducers<State>({
  list: list.reducer,
});
