export interface ThemeInterface {
  isSp: boolean;
  mainColor: string;
  baseColor: string;
  moderateColor: string;
  disableColor: string;
  baseTxtColor: string;
  disableTxtColor: string;
  failureTxtColor: string;
  accentTxtColor: string;
  moderateTxtColor: string;
  paleTxtColor: string;
  linkTxtColor: string;
  iconTxtColor: string;
  btnHighColor: string;
  btnMiddleColor: string;
  borderColor: string;
}

export const mainTheme = {
  isSp: true, // SPかどうか
  mainColor: '#68cb16', // メインカラー
  baseColor: '#58453f', // ベースカラー
  moderateColor: '#f5f5f5', // 薄めのカラー
  disableColor: '', // 非活性カラー
  baseTxtColor: '#333', // ベース文字色
  disableTxtColor: '#ddd', // 非活性文字色
  failureTxtColor: '#b84a46', // エラー文字色
  accentTxtColor: '#468646', // アクセント文字色
  moderateTxtColor: '#666', // 薄めの文字色
  paleTxtColor: '#999', // さらに薄めの文字色
  linkTxtColor: '#3966f2', // リンク文字色
  iconTxtColor: '#b3df8f', // アイコン文字色
  btnHighColor: '#eb5a98', // ボタン（強）
  btnMiddleColor: '#f5abc4', // ボタン（中）
  borderColor: '#ddd', // ボーダー
};

// 文字
export interface CharacterTheme {
  charColor: string;
  charFontSize: number;
  charDecoration: string;
}

export const charModerateTheme = {
  charColor: mainTheme.moderateTxtColor,
  charFontSize: 12,
  charDecoration: 'none',
};
export const charAccentTheme = {
  charColor: mainTheme.accentTxtColor,
  charFontSize: 12,
  charDecoration: 'none',
};
export const charEmTheme = {
  charColor: mainTheme.btnHighColor,
  charFontSize: 15,
  charDecoration: 'none',
};
export const charPaleTheme = {
  charColor: mainTheme.paleTxtColor,
  charFontSize: 11,
  charDecoration: 'none',
};
export const charLinkPaleTheme = {
  charColor: mainTheme.paleTxtColor,
  charFontSize: 11,
  charDecoration: 'underline',
};
export const charLinkTheme = {
  charColor: mainTheme.linkTxtColor,
  charFontSize: 14,
  charDecoration: 'underline',
};
export const charFailureTheme = {
  charColor: mainTheme.failureTxtColor,
  charFontSize: 14,
  charDecoration: 'none',
};

// 絵文字入力欄の背景色
export interface PictographTheme {
  bgColor: string;
}

export const pictographGrayTheme = {
  bgColor: '#f5f5f5',
};
export const pictographWhiteTheme = {
  bgColor: '#fff',
};
