import { connect } from 'react-redux';
import { RootState } from '../../../store';
import Component from './Component';

/**
 * 投稿ボタン
 */
const mapStateToProps = (state: RootState) => {
  const session = state.commons.session;

  return {
    session,
  };
};

export default connect(mapStateToProps)(Component);
