import { combineReducers } from 'redux';
import * as topic from './topic';

export type State = {
  topic: topic.State;
};

export const defaultState = {
  topic: topic.defaultState,
};

export const reducer = combineReducers<State>({
  topic: topic.reducer,
});
