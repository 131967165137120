import { Action as ReduxAction, createAction } from 'redux-actions';
import * as Pager from '../../domain/Pager';
import * as Topic from '../../domain/Topic';

/**
 * action
 */
export const REQUEST_FETCH_MY_CLIP_LIST = 'REQUEST_FETCH_MY_CLIP_LIST';
export const DONE_FETCH_MY_CLIP_LIST = 'DONE_FETCH_MY_CLIP_LIST';

export type PAYLOAD_REQUEST_FETCH_MY_CLIP_LIST = Pager.Value;
export type PAYLOAD_DONE_FETCH_MY_CLIP_LIST = Topic.EntityList;

export interface RequestFetchMyClipList extends ReduxAction<PAYLOAD_REQUEST_FETCH_MY_CLIP_LIST> {}
export interface DoneFetchMyClipList extends ReduxAction<PAYLOAD_DONE_FETCH_MY_CLIP_LIST> {}

export type Payload = PAYLOAD_REQUEST_FETCH_MY_CLIP_LIST | PAYLOAD_DONE_FETCH_MY_CLIP_LIST;

export type Action = RequestFetchMyClipList | DoneFetchMyClipList;

export const actionCreators = {
  requestFetchMyClipList: createAction<PAYLOAD_REQUEST_FETCH_MY_CLIP_LIST>(REQUEST_FETCH_MY_CLIP_LIST),
  doneFetchMyClipList: createAction<PAYLOAD_DONE_FETCH_MY_CLIP_LIST>(DONE_FETCH_MY_CLIP_LIST),
};

/**
 * reducer
 */
export type State = Pager.Value;

export const defaultState = new Pager.Value();

const init = (state: object) => Pager.Value.fromJS(state);
const replace = (state: State, action: Action) => {
  const payload = action.payload as PAYLOAD_REQUEST_FETCH_MY_CLIP_LIST;

  // リストが一瞬空にならないように items と件数を引き継ぐ
  return payload.withMutations(pager => {
    return pager.set('items', state.items).set('totalCount', state.totalCount);
  });
};
const merge = (state: State, action: Action) => state.mergeResult(action.payload as PAYLOAD_DONE_FETCH_MY_CLIP_LIST);

export const reducer = (state: State = defaultState, action: Action): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case REQUEST_FETCH_MY_CLIP_LIST:
      return replace(newState, action);

    case DONE_FETCH_MY_CLIP_LIST:
      return merge(newState, action);

    default:
      return newState;
  }
};
