import React from 'react';

/**
 * JSON-LD をレンダリングする
 */
interface JsonLdProps {
  data: {
    '@context': string;
    '@type': string;
    itemListElement: {
      '@type': string;
      position: number;
      item: {
        '@id': string;
        name: string;
      };
    }[];
  };
}

const JsonLd: React.FC<JsonLdProps> = props => {
  const { data } = props;

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />;
};

export default JsonLd;
