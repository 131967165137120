import { actionCreators, Action } from './pane';
import RepositoryFactory from '../../repositories/RepositoryFactory';

/**
 * 共通メニューのアクション
 */
export default class PaneActionDispatcher {
  private dispatch: (action: Action) => Action;

  constructor(dispatch: (action: Action) => Action) {
    this.dispatch = dispatch;
    this.fetch = this.fetch.bind(this);
  }

  /**
   * 共通ヘッダー、フッター、サイドバーを取得する
   */
  public async fetch(loginSessionToken: string, isSp: boolean): Promise<void> {
    const repository = RepositoryFactory.createPaneRepository();
    const [headerHtml, footerHtml, sideHtml] = await Promise.all([
      repository.findHeader(loginSessionToken, isSp),
      repository.findFooter(loginSessionToken, isSp),
      isSp ? '' : repository.findSide(loginSessionToken, isSp),
    ]);
    this.dispatch(actionCreators.doneFetchPane(headerHtml, footerHtml, sideHtml));
  }
}
