import * as Session from '../../domain/Session';
import RepositoryFactory from '../../repositories/RepositoryFactory';
import { actionCreators, Action } from './list';

/**
 * カテゴリのアクション
 */
export default class CategoryActionDispatcher {
  private dispatch: (action: Action) => Action;
  private session: Session.Value;

  constructor(dispatch: (action: Action) => Action, session: Session.Value) {
    this.dispatch = dispatch;
    this.session = session;

    this.fetchList = this.fetchList.bind(this);
  }

  /**
   * カテゴリ一覧を取得して保存する
   *
   * @returns {Promise<void>}
   */
  public async fetchList(): Promise<void> {
    const repository = RepositoryFactory.createCategoryRepository(this.session);
    const categories = await repository.findAll();
    this.dispatch(actionCreators.doneFetchCategoryList(categories));
  }
}
