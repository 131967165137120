import immutable from 'immutable';
import Utils from '../libs/utils';
import * as User from '../domain/User';

type DecodedLoginSessionToken = {
  sub: number;
  tokens: {
    access_token: string;
  };
};

/**
 * セッションバリューオブジェクト
 */
type SessionValue = {
  isSp: boolean;
  loginSessionToken: string;
  accessToken: string;
  user: User.Entity;
  isGoogleBot: boolean;
};

const sessionDefaultValue = {
  isSp: true,
  loginSessionToken: '',
  accessToken: '',
  user: new User.Entity(),
  isGoogleBot: false,
};

const ValueRecord: immutable.Record.Factory<SessionValue> = immutable.Record(sessionDefaultValue);
export class Value extends ValueRecord {
  private _decodedLoginSessionToken: DecodedLoginSessionToken;

  /**
   * オブジェクトからバリューオブジェクトを生成する
   *
   * @param {object} data
   * @returns {Value}
   */
  public static fromJS(data: object): Value {
    const value = immutable.fromJS(data).withMutations((value: immutable.Map<string, object>) => {
      return value.updateIn(['user'], (user: object) => new User.Entity(user));
    });

    return new Value(value);
  }

  /**
   * ログインセッショントークンからバリューオブジェクトを生成する
   *
   * @param {string} loginSessionToken
   * @returns {Value}
   */
  public static createFromLoginSessionToken(loginSessionToken: string): Value {
    const value = new Value({ loginSessionToken });

    return value.set('accessToken', value.decodeLoginSessionToken.tokens.access_token);
  }

  /**
   * デコードしたログインセッショントークンを返す
   *
   * @returns {DecodedLoginSessionToken}
   */
  public get decodeLoginSessionToken(): DecodedLoginSessionToken {
    if (!this._decodedLoginSessionToken) {
      this._decodedLoginSessionToken = Utils.decodeJwtToken<DecodedLoginSessionToken>(this.loginSessionToken);
    }

    return this._decodedLoginSessionToken;
  }

  /**
   * ユーザエンティティが等しいかどうかを判定する
   *
   * @param {User.Entity} user
   * @returns {boolean}
   */
  public isEqualUser(user: User.Entity): boolean {
    return this.user.isEqual(user);
  }

  /**
   * ゲストかどうかを返す
   *
   * @return {boolean}
   */
  public isGuest(): boolean {
    return this.loginSessionToken === '';
  }
}
