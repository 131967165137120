import * as Comment from '../../domain/Comment';
import * as detail from '../comments/detail';
import * as list from '../comments/list';

/**
 * reducer
 */
export type State = Comment.EntityList;

export const defaultState = new Comment.EntityList();

const init = (state: object) => Comment.EntityList.fromJS(state);
const mergeEntity = (state: State, action: detail.Action) => state.replaceEntity(action.payload as Comment.Entity);
const mergeEntityList = (state: State, action: list.Action) => state.mergeList(action.payload as list.PAYLOAD_DONE_FETCH_COMMENT_LIST);

export const reducer = (state: State = defaultState, action: detail.Action | list.Action): State => {
  const newState = state.toJS ? state : init(state);

  switch (action.type) {
    case detail.DONE_SEND_COMMENT_HEART:
      return mergeEntity(newState, action as detail.Action);

    case list.DONE_FETCH_COMMENT_LIST:
      return mergeEntityList(newState, action as list.Action);

    default:
      return newState;
  }
};
